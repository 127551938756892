import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import './NavMenu.css';


export class Layout extends Component {
  static displayName = Layout.name;
  

  render () {
    return (
        <div className="w-screen overflow-hidden" id="app scroll-smooth">
        <NavMenu />
          {this.props.children}
            <Footer />
      </div>
    );
  }
}
