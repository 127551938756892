import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';

import { Link } from 'react-router-dom';

export class AboutUs extends Component {
    static displayName = AboutUs.name;
    constructor(props) {
        super(props);
        this.state = { AboutUsData: [], loading: true };
    }
    componentDidMount() {
        this.populateAboutUs();
    }
    refreshData = (data) => {
        this.populateAboutUs();
    };
    render() {
        ReactSession.setStoreType("localStorage");

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderAboutUsTable(this.state.AboutUsData, this.refreshData);
        return (
            <div>
                <div className="w-screen / py-18px sm:py-100px lg:py-100px / relative / animate-fadein animation-delay-500ms / gutenberg">
                    <p></p>
                    <div className="mobiscreen max-w-screen-xl  / px-32px / mx-auto mt-28px sm:mt-48px / relative z-10">
                        <div className="text-center">
                            <h1>About ProfitSheets</h1>
                            <span className="d-block mb-4">We will provide deep insight info on strategic trading and financial planning.</span>
                        </div>




                        <div className="mx-auto subscription aboutus-text">
                            <div className="col100 mt4  flex-align-center">

                                <div className="bg-white / px-48px sm:px-16px md:px-32px xl:px-32px py-32px / rounded-8px sm:rounded-none md:rounded-8px / shadow-xl sm:shadow-none md:shadow-2xl / flex flex-col text-justify">
                                     <h3 className="font-weight-bold">We are start-up</h3>
                                    <p>As we are start-up in the field of Financial/Stock market, profitsheets.com is committed to provide accurate info on Financial news, Business news, company financial reports, stock related data.
                                        Profitsheets.com aim to provide appropriate information about various investment instruments available in the market, we will provide deep insight info on strategic trading and financial planning.
                                        In coming days, Profitsheets.com team are dedicated to provide information for all the investors who are looking for genuine guidance and information on stock market trading in India. <strong>Our success lies in the success of our readers.</strong> We cover everything from basics to technical analysis in the field of stock market.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>






                    <div className="w-full / absolute bottom-1/2 left-0">
                        <div className="max-w-screen-xl / mx-auto / relative">


                            <svg className="w-80pc md:w-50pc / absolute bottom-0 right-95pc / animate-float-15" viewBox="0 0 697 663" fill="none" xmlns="http://www.w3.org/2000/svg">
                                {/* <path d="M697 565C697 555.983 693.523 547.335 687.335 540.958C681.146 534.582 672.752 531 664 531C655.248 531 646.854 534.582 640.665 540.958C634.477 547.335 631 555.983 631 565L664 565H697Z" fill="#1a9926" /> */}
                                <ellipse cx="324.5" cy="331.5" rx="324.5" ry="331.5" fill="#124d99" />
                            </svg>



                        </div>
                    </div>


                    <div className="w-full / absolute bottom-0 left-0">
                        <div className="max-w-screen-xl / mx-auto / relative">

                            <svg className="w-40pc md:w-20pc / absolute bottom-0 right-90pc / animate-float-15" viewBox="0 0 227 169" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M34 75C24.9826 75 16.3346 71.4179 9.95837 65.0416C3.58213 58.6654 -2.50846e-07 50.0174 0 41C2.50846e-07 31.9826 3.58213 23.3346 9.95837 16.9584C16.3346 10.5821 24.9826 7 34 7L34 41L34 75Z" fill="#124d99" />
                                <ellipse cx="144" cy="84.5" rx="83" ry="84.5" fill="#1a9926" />
                            </svg>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
    async populateAboutUs() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");

    }
}
