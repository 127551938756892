import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactSession } from 'react-client-session';



export class PreviousCalls extends Component {
    static displayName = PreviousCalls.name;
    constructor(props) {
        super(props);
        this.state = { PreviousCallsData: [], loading: true };
    }
    componentDidMount() {
        this.populateTodayCalls();
    }
    refreshData = (data) => {
        this.populateTodayCalls();
    };
    renderPreviousCallsTable(PreviousCallsData, refreshData) {
        var $today = new Date();
        var $yesterday = new Date($today);
        $yesterday.setDate($today.getDate() - 2);
        var $daybefore = new Date($today);
        $daybefore.setDate($today.getDate() - 3);
        var $dd = $yesterday.getDate();
        var $mm = $yesterday.getMonth() + 1; //January is 0!
        var $yyyy = $yesterday.getFullYear();

        return (
            <div className="w-screen / py-18px sm:py-100px lg:py-100px / relative / animate-fadein animation-delay-500ms / gutenberg">
                <p></p>
                <div className="max-w-screen-xl  / px-32px / mx-auto mt-28px sm:mt-48px / relative z-10 mobiscreen">
                    <div className="text-center">
                        <h1>Calls</h1>
                        <span>Get the tiger's eye view on short & medium term trends,<br />  along with alerts & updates!</span>
                    </div>

                    {ReactSession.get("token") == undefined ? <>
                 
                    </> :
                                    <>
                            <div className='text-right pt-4'>
                                <Link to='/todaycalls' className='text-right text-dark h6'>
                                    See Today Calls <i className='fa fa-angle-double-right'></i>
                                </Link>
                            </div>
                        </>
                    }

                    <div className="mx-auto subscription ">
                        <div className="col100 mt4  flex-align-center">
                            <h6 className="pb-3"><strong>Yesterday</strong></h6>
                            <div className="padd-0-mobi bg-white / px-48px sm:px-16px md:px-32px xl:px-32px py-32px / rounded-8px sm:rounded-none md:rounded-8px / shadow-xl sm:shadow-none md:shadow-2xl / flex flex-col text-center">
                                <div className="table-responsive">
                                <table className="table table-bordered position-relative">
                                    <thead>
                                        <tr>
                                            <th scope="col">Index Stock</th>
                                            <th scope="col">Trade Type</th>
                                            <th scope="col">Stock</th>
                                            <th scope="col">Call Type</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Target -1</th>
                                            <th scope="col">Target -2</th>
                                            <th scope="col">Target -3</th>
                                            <th scope="col">SL</th>
                                            <th scope="col">Total Gain</th>
                                        </tr>
                                    </thead>
                                    <tbody>



                                        {PreviousCallsData.data1.length == 0 ? <td align="center" valign="middle" colSpan="10">No Stock Call Data</td> : PreviousCallsData.data1.map(w =>

                                            <tr key={w.call_Id}>
                                                <td align="center" valign="middle">
                                                    {w.stock_Index_Id}
                                                </td>
                                                <td align="center" valign="middle">
                                                    {w.trade_Id}
                                                </td>
                                                <td align="center" valign="middle" className="left sorting_1">
                                                    <span className="full_grid">
                                                        <span className="toolTip half_grid ng-isolate-scope" pm-tool-tip="" tip-direction="right" index="8" href="javascript:void(0)">
                                                            <b>{w.stockName}</b>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td align="center" valign="middle">
                                                    {w.type_Id == 1 ? <a href="javascript:void(0)" className="buy">Buy </a> : <a href="javascript:void(0)" className="sell">Sell </a>}
                                                </td>
                                                {/*<td>*/}

                                                {/*</td>*/}
                                                <td>
                                                    {w.price}
                                                </td>
                                                <td>
                                                    {/*{w.trans_type == 1 ? '' + { w.trans_amount } +'' : '0'}*/}
                                                    {w.t1}
                                                </td>
                                                <td>
                                                    {w.t2 == 0 ? 'N/A' : w.t2}



                                                </td>
                                                <td>
                                                    {w.t3== 0 ? 'N/A' : w.t3}


                                                </td>
                                                <td>
                                                    {w.sl == 0 ? 'N/A' : w.sl}
                                                </td>
                                                <td>

                                                    {w.gain == 0 ? 'N/A' : w.gain}
                                                </td>
                                            </tr>
                                        )}

                                    </tbody>
                                    </table>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <p></p>
                    <div className="mx-auto subscription ">
                        <div className="col100 mt4  flex-align-center">
                            <h6 className="pb-3"><strong style={{ border: "1px solid #8e8a8a", padding: "5px"}}>{$dd + '-' + $mm + '-' + $yyyy}</strong></h6>
                            <div className="padd-0-mobi bg-white / px-48px sm:px-16px md:px-32px xl:px-32px py-32px / rounded-8px sm:rounded-none md:rounded-8px / shadow-xl sm:shadow-none md:shadow-2xl / flex flex-col text-center">
                                <div className="table-responsive">
                                <table className="table table-bordered position-relative">
                                    <thead>
                                        <tr>
                                            <th scope="col">Index Stock</th>
                                            <th scope="col">Trade Type</th>
                                            <th scope="col">Stock</th>
                                            <th scope="col">Call Type</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Target -1</th>
                                            <th scope="col">Target -2</th>
                                            <th scope="col">Target -3</th>
                                            <th scope="col">SL</th>
                                            <th scope="col">Total Gain</th>
                                        </tr>
                                    </thead>
                                    <tbody>



                                        {PreviousCallsData.data2.length == 0 ? <td align="center" valign="middle" colSpan="10">No Stock Call Data</td> : PreviousCallsData.data2.map(w =>
                                            <tr key={w.call_Id}>
                                                <td align="center" valign="middle">
                                                    {w.stock_Index_Id}
                                                </td>
                                                <td align="center" valign="middle">
                                                    {w.trade_Id}
                                                </td>
                                                <td align="center" valign="middle" className="left sorting_1">
                                                    <span className="full_grid">
                                                        <span className="toolTip half_grid ng-isolate-scope" pm-tool-tip="" tip-direction="right" index="8" href="javascript:void(0)">
                                                            <b>{w.stockName}</b>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td align="center" valign="middle">
                                                    {w.type_Id == 1 ? <a href="javascript:void(0)" className="buy">Buy </a> : <a href="javascript:void(0)" className="sell">Sell </a>}
                                                </td>
                                                {/*<td>*/}

                                                {/*</td>*/}
                                                <td>
                                                    {w.price}
                                                </td>
                                                <td>
                                                    {/*{w.trans_type == 1 ? '' + { w.trans_amount } +'' : '0'}*/}
                                                    {w.t1}
                                                </td>
                                                <td>
                                                    {w.t2}


                                                </td>
                                                <td>
                                                    {w.t3}

                                                </td>
                                                <td>
                                                    {w.sl}
                                                </td>
                                                <td>

                                                    {w.gain == 0 ? 'N/A' : w.gain}
                                                </td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                                </div>
                                </div>
                        </div>
                    </div>
                    <p></p>
                    <div className="mx-auto subscription ">
                        <div className="col100 mt4  flex-align-center">
                            <h6 className="pb-3"><strong style={{ border: "1px solid #8e8a8a", padding: "5px" }}>{$daybefore.getDate() + '-' + $mm + '-' + $yyyy} </strong></h6>
                            <div className="padd-0-mobi bg-white / px-48px sm:px-16px md:px-32px xl:px-32px py-32px / rounded-8px sm:rounded-none md:rounded-8px / shadow-xl sm:shadow-none md:shadow-2xl / flex flex-col text-center">
                                <div className="table-responsive">
                                <table className="table table-bordered position-relative">
                                    <thead>
                                        <tr>
                                            <th scope="col">Index Stock</th>
                                            <th scope="col">Trade Type</th>
                                            <th scope="col">Stock</th>
                                            <th scope="col">Call Type</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Target -1</th>
                                            <th scope="col">Target -2</th>
                                            <th scope="col">Target -3</th>
                                            <th scope="col">SL</th>
                                            <th scope="col">Total Gain</th>
                                        </tr>
                                    </thead>
                                    <tbody>



                                        {PreviousCallsData.data3.length == 0 ? <td align="center" valign="middle" colSpan="10">No Stock Call Data</td> : PreviousCallsData.data3.map(w =>
                                            <tr key={w.call_Id}>
                                                <td align="center" valign="middle">
                                                    {w.stock_Index_Id}
                                                </td>
                                                <td align="center" valign="middle">
                                                    {w.trade_Id}
                                                </td>
                                                <td align="center" valign="middle" className="left sorting_1">
                                                    <span className="full_grid">
                                                        <span className="toolTip half_grid ng-isolate-scope" pm-tool-tip="" tip-direction="right" index="8" href="javascript:void(0)">
                                                            <b>{w.stockName}</b>
                                                        </span>
                                                    </span>
                                                </td>
                                                <td align="center" valign="middle">
                                                    {w.type_Id == 1 ? <a href="javascript:void(0)" className="buy">Buy </a> : <a href="javascript:void(0)" className="sell">Sell </a>}
                                                </td>
                                                {/*<td>*/}

                                                {/*</td>*/}
                                                <td>
                                                    {w.price}
                                                </td>
                                                <td>
                                                    {/*{w.trans_type == 1 ? '' + { w.trans_amount } +'' : '0'}*/}
                                                    {w.t1}
                                                </td>
                                                <td>
                                                    {w.t2}


                                                </td>
                                                <td>
                                                    {w.t3}

                                                </td>
                                                <td>
                                                    {w.sl}
                                                </td>
                                                <td>

                                                    {w.gain == 0 ? 'N/A' : w.gain}
                                                </td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>
                                </div>
                                </div>
                        </div>
                    </div>

                </div>






                <div className="w-full / absolute bottom-1/2 left-0">
                    <div className="max-w-screen-xl / mx-auto / relative">


                        <svg className="w-80pc md:w-50pc / absolute bottom-0 right-95pc / animate-float-15" viewBox="0 0 697 663" fill="none" xmlns="http://www.w3.org/2000/svg">
                            {/* <path d="M697 565C697 555.983 693.523 547.335 687.335 540.958C681.146 534.582 672.752 531 664 531C655.248 531 646.854 534.582 640.665 540.958C634.477 547.335 631 555.983 631 565L664 565H697Z" fill="#1a9926" /> */}
                            <ellipse cx="324.5" cy="331.5" rx="324.5" ry="331.5" fill="#124d99" />
                        </svg>



                    </div>
                </div>


                <div className="w-full / absolute bottom-0 left-0">
                    <div className="max-w-screen-xl / mx-auto / relative">

                        <svg className="w-40pc md:w-20pc / absolute bottom-0 right-90pc / animate-float-15" viewBox="0 0 227 169" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M34 75C24.9826 75 16.3346 71.4179 9.95837 65.0416C3.58213 58.6654 -2.50846e-07 50.0174 0 41C2.50846e-07 31.9826 3.58213 23.3346 9.95837 16.9584C16.3346 10.5821 24.9826 7 34 7L34 41L34 75Z" fill="#124d99" />
                            <ellipse cx="144" cy="84.5" rx="83" ry="84.5" fill="#1a9926" />
                        </svg>


                    </div>
                </div>
            </div>
        );
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderPreviousCallsTable(this.state.PreviousCallsData, this.refreshData);
        return (
            <div>
                {contents}
            </div>
        );
    }
    async populateTodayCalls() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/api/Values/GetPreviousCalls')
        if (response.status == '401') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            this.setState({ PreviousCallsData: data.data, loading: false });
        }
    }
}
