import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';

import { Link } from 'react-router-dom';

export class PrivacyPolicy extends Component {
    static displayName = PrivacyPolicy.name;
    constructor(props) {
        super(props);
        this.state = { PrivacyPolicyData: [], loading: true };
    }
    componentDidMount() {
        this.populatePrivacyPolicy();
    }
    refreshData = (data) => {
        this.populatePrivacyPolicy();
    };
    render() {
        ReactSession.setStoreType("localStorage");

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderPrivacyPolicyTable(this.state.PrivacyPolicyData, this.refreshData);
        return (
            <div>
                <div className="w-screen / py-18px sm:py-100px lg:py-100px / relative / animate-fadein animation-delay-500ms / gutenberg">
                    <p></p>
                    <div className="mobiscreen max-w-screen-xl  / px-32px / mx-auto mt-28px sm:mt-48px / relative z-10">
                        <div className="text-center">
                            <h1>Privacy Policy</h1>
                            <span className="d-block col-xl-8 pt-3 text-center mx-auto mb-4">A privacy policy is a statement or legal document that discloses some or all of the ways a party gathers, uses, discloses, and manages a customer or client's data.</span>
                        </div>




                        <div className="mx-auto subscription privacypolicy-text">
                            <div className="col100 mt4  flex-align-center">

                                <div className="bg-white / px-48px sm:px-16px md:px-32px xl:px-32px py-32px / rounded-8px sm:rounded-none md:rounded-8px / shadow-xl sm:shadow-none md:shadow-2xl / flex flex-col text-justify">
                                    <h3 className="font-weight-bold">General Disclaimer</h3>
                                    <p>The information available regarding the stocks/Indices or News on this website is for educational purposes only. 
                                    </p>
                                    <p>The advices on the Shares/Stocks/Indices mentioned in the website and that should not be considered as any kind of buy, sell or any recommendation/advisory.<strong>Please do your own study or take the help of your financial advisor before taking investment decisions/planning.</strong>  </p>
                                    <p>The information and options on Shares/Stocks/Indices related trading found on this website and in its related products/ articles/ stock tips are written based on the best data available in the market.
                                        Neither the company nor any of its employees or owners shall be held liable or responsible for any error on this website or for any damage you may suffer as a result of failing to seek competent financial advice from a professional who is aware of the facts and circumstance of your individual situation.
                                    </p>
                                    <p>Our team have done our best to ensure that the information provided on this website and resources available for download are accurate and provided valuable information. Neither the company nor any of its employees or owners shall be liable or responsible for any errors on this website or for any damage you may suffer as a result of failing to seek competent financial advice from a professional.</p>

                                    <h3 className="font-weight-bold mt-4">Privacy Policy</h3>
                                    <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data. With reference to the data of the users who visit or communicate with our website. we present this privacy policy. Users are requested to refer to this policy before using our website.
                                        We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions policy.
                                    </p>
                                    <p>All the personal information of the users visiting our website including email address, IP address, and others contacts are purely stored only for records. This data might be used only for a general analytical purpose. Users will be visiting our website by their own free will. Any personally identifiable data shared by the user should be shared only by his/her own free will. The website is not binding any user to share their personal data unless voluntarily.</p>
                                    <h3 className="font-weight-bold mt-4">Cookies Data </h3>
                                    <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
                                    <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. We are using the technology is to improve and analyze our Service.</p>
                                    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
                                        Examples of Cookies we use:
                                    </p>

                                    <ul>
                                        <li>1) Session Cookies. We use Session Cookies to operate our Service.</li>
                                        <li>2) Preference Cookies. We use Preference Cookies to remember your preferences.</li>
                                        <li>3) Security Cookies. We use Security Cookies for security purposes.</li>
                                    </ul>
                                    <h3 className="font-weight-bold mt-4">Parent Guidance policy </h3>
                                    <p>Our Service does not address anyone under the age of 18 or below. ("Children").
                                        We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
                                    </p>
                                    <h3 className="font-weight-bold mt-4">Security of Data</h3>
                                    <p>The security of your data is important to us, the personally identifiable information will not be shared with any third party or any website and it is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
                                    <h3 className="font-weight-bold mt-4">Changes to This Privacy Policy</h3>
                                    <p>This privacy policy is subject to modifications and updates without any permission and intimation of the users. Users are requested to check the privacy policy for the updates. It is our legal right to change policy at any time.</p>
                                </div>
                            </div>
                        </div>
                    </div>






                    <div className="w-full / absolute bottom-1/2 left-0">
                        <div className="max-w-screen-xl / mx-auto / relative">


                            <svg className="w-80pc md:w-50pc / absolute bottom-0 right-95pc / animate-float-15" viewBox="0 0 697 663" fill="none" xmlns="http://www.w3.org/2000/svg">
                                {/* <path d="M697 565C697 555.983 693.523 547.335 687.335 540.958C681.146 534.582 672.752 531 664 531C655.248 531 646.854 534.582 640.665 540.958C634.477 547.335 631 555.983 631 565L664 565H697Z" fill="#1a9926" /> */}
                                <ellipse cx="324.5" cy="331.5" rx="324.5" ry="331.5" fill="#124d99" />
                            </svg>



                        </div>
                    </div>


                    <div className="w-full / absolute bottom-0 left-0">
                        <div className="max-w-screen-xl / mx-auto / relative">

                            <svg className="w-40pc md:w-20pc / absolute bottom-0 right-90pc / animate-float-15" viewBox="0 0 227 169" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M34 75C24.9826 75 16.3346 71.4179 9.95837 65.0416C3.58213 58.6654 -2.50846e-07 50.0174 0 41C2.50846e-07 31.9826 3.58213 23.3346 9.95837 16.9584C16.3346 10.5821 24.9826 7 34 7L34 41L34 75Z" fill="#124d99" />
                                <ellipse cx="144" cy="84.5" rx="83" ry="84.5" fill="#1a9926" />
                            </svg>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
    async populatePrivacyPolicy() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");

    }
}
