import React, { useEffect, useRef, Component } from 'react';
import { ReactSession } from 'react-client-session';
import { Link } from 'react-router-dom';
import AdSense from 'react-adsense';
import addImage from './wp-content/uploads/2021/ads-image.png';
import { Helmet } from "react-helmet";
import Safe from "react-safe";
import $ from 'jquery';


/*import GainWidget from '../components/GainWidget';*/

function GoogleAds(props) {
    const { currentPath } = props
    useEffect(() => {
        window.adsbygoogle = window.adsbygoogle || []
        window.adsbygoogle.push({})
    }, [])
}

export class Dashboard extends Component {
  

    static displayName = Dashboard.name;
    constructor(props) {
        super(props);
        this.state = { NewsData: [], NewsIData: [], loading: true };
        this.myRef = React.createRef();
        
    }
  


    componentDidMount() {
        this.populateNews();
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        //document.body.appendChild(script);
        const script3 = document.createElement('script');
        script3.src = 'https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js'
        script3.async = false;
        script3.innerHTML = JSON.stringify({
            "colorTheme": "light",
            "dateRange": "1M",
            "exchange": "BSE",
            "showChart": true,
            "locale": "en",
            "largeChartUrl": "",
            "isTransparent": false,
            "showSymbolLogo": false,
            "showFloatingTooltip": false,
            "width": "100%",
            "height": "500",
            "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
            "plotLineColorFalling": "rgba(41, 98, 255, 1)",
            "gridLineColor": "rgba(240, 243, 250, 0)",
            "scaleFontColor": "rgba(106, 109, 120, 1)",
            "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
            "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
            "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
            "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
            "symbolActiveColor": "rgba(41, 98, 255, 0.12)"
        })
        this.myRef.current.appendChild(script3);



        const script4 = document.createElement('script');
        script4.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js'
        script4.async = false;
        script4.innerHTML = JSON.stringify({
            "colorTheme": "light",
            "dateRange": "1D",
            "showChart": true,
            "locale": "en",
            "largeChartUrl": "",
            "isTransparent": false,
            "showSymbolLogo": true,
            "showFloatingTooltip": false,
            "width": "100%",
            "height": "500",
            "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
            "plotLineColorFalling": "rgba(41, 98, 255, 1)",
            "gridLineColor": "rgba(240, 243, 250, 0)",
            "scaleFontColor": "rgba(106, 109, 120, 1)",
            "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
            "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
            "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
            "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
            "symbolActiveColor": "rgba(41, 98, 255, 0.12)",
            "tabs": [
                {
                    "title": "Indices",
                    "symbols": [
                        {
                            "s": "FOREXCOM:SPXUSD",
                            "d": "S&P 500"
                        },
                        {
                            "s": "FOREXCOM:NSXUSD",
                            "d": "US 100"
                        },
                        {
                            "s": "FOREXCOM:DJI",
                            "d": "Dow 30"
                        },
                        {
                            "s": "INDEX:NKY",
                            "d": "Nikkei 225"
                        },
                        {
                            "s": "INDEX:DEU40",
                            "d": "DAX Index"
                        },
                        {
                            "s": "FOREXCOM:UKXGBP",
                            "d": "UK 100"
                        },
                        {
                            "s": "NSE:BANKNIFTY",
                            "d": "Bank Nifty"
                        },
                        {
                            "s": "NSE:NIFTY",
                            "d": "NIfty 50"
                        }
                    ],
                    "originalTitle": "Indices"
                },
                {
                    "title": "Futures",
                    "symbols": [
                        {
                            "s": "CME_MINI:ES1!",
                            "d": "S&P 500"
                        },
                        {
                            "s": "CME:6E1!",
                            "d": "Euro"
                        },
                        {
                            "s": "COMEX:GC1!",
                            "d": "Gold"
                        },
                        {
                            "s": "NYMEX:CL1!",
                            "d": "Crude Oil"
                        },
                        {
                            "s": "NYMEX:NG1!",
                            "d": "Natural Gas"
                        },
                        {
                            "s": "CBOT:ZC1!",
                            "d": "Corn"
                        }
                    ],
                    "originalTitle": "Futures"
                },
                {
                    "title": "Bonds",
                    "symbols": [
                        {
                            "s": "CME:GE1!",
                            "d": "Eurodollar"
                        },
                        {
                            "s": "CBOT:ZB1!",
                            "d": "T-Bond"
                        },
                        {
                            "s": "CBOT:UB1!",
                            "d": "Ultra T-Bond"
                        },
                        {
                            "s": "EUREX:FGBL1!",
                            "d": "Euro Bund"
                        },
                        {
                            "s": "EUREX:FBTP1!",
                            "d": "Euro BTP"
                        },
                        {
                            "s": "EUREX:FGBM1!",
                            "d": "Euro BOBL"
                        }
                    ],
                    "originalTitle": "Bonds"
                },
                {
                    "title": "Forex",
                    "symbols": [
                        {
                            "s": "FX:EURUSD",
                            "d": "EUR/USD"
                        },
                        {
                            "s": "FX:GBPUSD",
                            "d": "GBP/USD"
                        },
                        {
                            "s": "FX:USDJPY",
                            "d": "USD/JPY"
                        },
                        {
                            "s": "FX:USDCHF",
                            "d": "USD/CHF"
                        },
                        {
                            "s": "FX:AUDUSD",
                            "d": "AUD/USD"
                        },
                        {
                            "s": "FX:USDCAD",
                            "d": "USD/CAD"
                        }
                    ],
                    "originalTitle": "Forex"
                }
            ]
        })
        document.getElementById("myContainer").appendChild(script4);


    }
    refreshData = (data) => {
        this.populateNews();
    };
    renderNewsTable(NewsData, refreshData) {

        return (

            NewsData.length == 0 ? <div className="text-center h6">No News Data</div> : NewsData.slice(0, 3).map(w =>
                <Link to={`News/${w.news_Title}/${w.news_Id}`} className="col-xl-4 mb-3">
                    <div className="box-wrap">
                        <img src={w.imagePath} alt="image" className="img-responsive" />
                        <small className='text-muted'>{w.created_On}</small>
                        <p className="font-title text-dark"><strong>{w.news_Title.replaceAll("-"," ")} </strong></p>
                        <small className="desc text-dark pb-0 mb-0" dangerouslySetInnerHTML={{ __html: w.news_Description.substring(0, 150) }}>
                        </small><small className="text-grey">Read more...</small>
                    </div>
                </Link>

            )
            //</div>

        );
    }

    renderNewsITable(News, refreshData) {
        return (
            News.length == 0 ? <div className="text-center h6">No News Data</div> : News.slice(0, 12).map(w =>
                <div key={w.news_Id} className="col-xl-4 mb-3">
                    <Link to={`News/${w.news_Title}/${w.news_Id}`} className="card-gaCYEutU">
                        <article className="card-exterior-RYyl75pN article-gaCYEutU">
                            <div className="container-gaCYEutU">
                                <div className="header-gaCYEutU">
                                    <span className="breadcrumbs-gaCYEutU"><time datetime="Thu, 29 Dec 2022 12:05:07 GMT"><i className="fa fa-clock text-dark"></i>  {w.created_On}</time></span>
                                    {/* <span className="name">Reuters</span></span>*/}
                                </div>
                                <span className="title-gaCYEutU">{w.news_Title.replaceAll("-", " ")}</span>
                            </div>
                        </article>
                    </Link>
                </div>
            )
        );
    }
    renderNewsIITable(NewsIData, refreshData) {
        return (
            NewsIData.length == 0 ? <div className="text-center h6">No News Data</div> : NewsIData.slice(0, 1).map(w =>
                <div className="col-xl-6 mb-3 mx-auto">
                    <h2 className="text-dark h1 pb-3">{w.news_Title}</h2>
                    <span className="date small"><i className="fa fa-clock text-dark"></i> {w.created_On}</span>
                    {ReactSession.get("token") == undefined ? <>
                        <p className="h6 font-weight-normal pt-4 pb-3">{w.news_Description.substring(0, 150)}...</p>
                        <div className="fullstory-box bg-blue p-4">
                            <div className="row">
                                <div className="col-xl-9 text-white">
                                    <h4 className="h2">Keep reading</h4>
                                    <p >Log in or sign up for a free TradingView account, and enjoy no holds barred access to global finance news, in real-time.</p>
                                    <Link to='#' className="btn-success btn-lg mt-4 d-inline-block text">Get full story</Link>
                                </div>
                                <div className="col-xl-3 text-center mx-auto">
                                    <i className="fa fa-lock-alt text-white fa-5x pt-5"></i>
                                </div>
                            </div>
                        </div>
                    </> :
                        <>
                            <p className="h6 font-weight-normal pt-4 pb-3">{w.news_Description}</p>
                        </>

                    }
                </div>
            )
            //</div>
        );
    }



    render() {

        setTimeout(function () {
            $('.header, .footer').show();
        }, 1);
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderNewsTable(this.state.NewsData, this.refreshData);
        let contents1 = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderNewsITable(this.state.NewsIData, this.refreshData);
        let contents2 = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderNewsIITable(this.state.NewsIData, this.refreshData);
        function showpopuo(id) {
            alert();
        }
        setTimeout(function () {



            $('.text').on("click", function () {
                $('.popup').css('opacity', 1);
                $('.overlay').css('opacity', 0.8);
                $('.popup').css('display', 'block');
                $('.overlay').css('display', 'block');
            });
            // close popup
            $('.popup-close').on("click", function () {
                $('.popup').css('opacity', 0);
                $('.overlay').css('opacity', 0);
                setTimeout(function () {
                    $('.popup').css('display', 'none');
                    $('.overlay').css('display', 'none');
                }, 10);

            });


        }, 1);

        setTimeout(function () {
            $('.card-gaCYEutU').on("click", function () {
                $('.popupp').css({ 'opacity': '1', 'visibility': 'visible' });
                $('.overlayy').css({ 'opacity': '1', 'visibility': 'visible' });
            });
            $('.close').on("click", function () {
                $('.popupp').css({ 'opacity': '0', 'visibility': 'hidden' });
                $('.overlayy').css({ 'opacity': '0', 'visibility': 'hidden' });
            });
        }, 1);

        $(document).ready(function ($) {
          
           
            $('#topgainers1').append(script1);


            const script2 = document.createElement("script");
            script2.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
            script2.async = true;
            $(script2).append(JSON.stringify({
                "colorTheme": "light",
                "dateRange": "1D",
                "showChart": true,
                "locale": "in",
                "largeChartUrl": "",
                "isTransparent": false,
                "showSymbolLogo": true,
                "showFloatingTooltip": false,
                "width": "400",
                "height": "660",
                "plotLineColorGrowing": "rgba(41, 98, 255, 1)",
                "plotLineColorFalling": "rgba(41, 98, 255, 1)",
                "gridLineColor": "rgba(240, 243, 250, 0)",
                "scaleFontColor": "rgba(106, 109, 120, 1)",
                "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
                "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
                "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
                "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
                "symbolActiveColor": "rgba(41, 98, 255, 0.12)",
                "tabs": [
                    {
                        "title": "Indices",
                        "symbols": [
                            {
                                "s": "FOREXCOM:SPXUSD",
                                "d": "S&P 500"
                            },
                            {
                                "s": "FOREXCOM:NSXUSD",
                                "d": "US 100"
                            },
                            {
                                "s": "FOREXCOM:DJI",
                                "d": "Dow 30"
                            },
                            {
                                "s": "INDEX:NKY",
                                "d": "Nikkei 225"
                            },
                            {
                                "s": "INDEX:DEU40",
                                "d": "DAX Index"
                            },
                            {
                                "s": "FOREXCOM:UKXGBP",
                                "d": "UK 100"
                            }
                        ],
                        "originalTitle": "Indices"
                    },
                    {
                        "title": "Futures",
                        "symbols": [
                            {
                                "s": "CME_MINI:ES1!",
                                "d": "S&P 500"
                            },
                            {
                                "s": "CME:6E1!",
                                "d": "Euro"
                            },
                            {
                                "s": "COMEX:GC1!",
                                "d": "Gold"
                            },
                            {
                                "s": "NYMEX:CL1!",
                                "d": "Crude Oil"
                            },
                            {
                                "s": "NYMEX:NG1!",
                                "d": "Natural Gas"
                            },
                            {
                                "s": "CBOT:ZC1!",
                                "d": "Corn"
                            }
                        ],
                        "originalTitle": "Futures"
                    },
                    {
                        "title": "Bonds",
                        "symbols": [
                            {
                                "s": "CME:GE1!",
                                "d": "Eurodollar"
                            },
                            {
                                "s": "CBOT:ZB1!",
                                "d": "T-Bond"
                            },
                            {
                                "s": "CBOT:UB1!",
                                "d": "Ultra T-Bond"
                            },
                            {
                                "s": "EUREX:FGBL1!",
                                "d": "Euro Bund"
                            },
                            {
                                "s": "EUREX:FBTP1!",
                                "d": "Euro BTP"
                            },
                            {
                                "s": "EUREX:FGBM1!",
                                "d": "Euro BOBL"
                            }
                        ],
                        "originalTitle": "Bonds"
                    },
                    {
                        "title": "Forex",
                        "symbols": [
                            {
                                "s": "FX:EURUSD",
                                "d": "EUR/USD"
                            },
                            {
                                "s": "FX:GBPUSD",
                                "d": "GBP/USD"
                            },
                            {
                                "s": "FX:USDJPY",
                                "d": "USD/JPY"
                            },
                            {
                                "s": "FX:USDCHF",
                                "d": "USD/CHF"
                            },
                            {
                                "s": "FX:AUDUSD",
                                "d": "AUD/USD"
                            },
                            {
                                "s": "FX:USDCAD",
                                "d": "USD/CAD"
                            }
                        ],
                        "originalTitle": "Forex"
                    }
                ]
            }));
            $('#makeaction').append(script2);
        })
      
        return (

            <div>
                  


                <div className="col-10 mx-auto p-0">
                    <div>
                        <div className="row">

                            {ReactSession.get("token") == undefined ? <>
                                <div className="col-12">
                                    <div className="table-resposive">
                                        <table className="table blurr position-relative">
                                            <thead>
                                                <tr><th scope="col">Index Stock</th>
                                                    <th scope="col">Trade Type</th>
                                                    <th scope="col">Stock</th>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Target -1</th>
                                                    <th scope="col">Target -2</th>
                                                    <th scope="col">Target -3</th>
                                                    <th scope="col">SL</th>
                                                    <th scope="col">Gain</th></tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td align="center" valign="middle">Nifty50</td>
                                                    <td align="center" valign="middle">Stock</td>
                                                    <td align="center" valign="middle" class="left sorting_1">
                                                        <span class="full_grid">
                                                            <span class="toolTip half_grid ng-isolate-scope" pm-tool-tip="" tip-direction="right" index="8" href="javascript:void(0)"><b>Reliance </b></span>
                                                        </span>
                                                    </td>
                                                    <td align="center" valign="middle"><a href="javascript:void(0)" class="buy">Buy </a></td>
                                                    <td>5000</td>
                                                    <td>2500</td>
                                                    <td>2000</td>
                                                    <td>1800</td>
                                                    <td>1900</td>
                                                    <td>N/A</td>
                                                </tr>
                                                <tr>
                                                    <td align="center" valign="middle">Stock</td>
                                                    <td align="center" valign="middle">BankNifty</td>
                                                    <td align="center" valign="middle" class="left sorting_1">
                                                        <span class="full_grid">
                                                            <span class="toolTip half_grid ng-isolate-scope" pm-tool-tip="" tip-direction="right" index="8" href="javascript:void(0)"><b>aspire</b></span>
                                                        </span>
                                                    </td>
                                                    <td align="center" valign="middle"><a href="javascript:void(0)" class="sell">Sell </a></td>
                                                    <td>2500</td>
                                                    <td>2200</td>
                                                    <td>2100</td>
                                                    <td>2000</td>
                                                    <td>1800</td>
                                                    <td>N/A</td>
                                                </tr>
                                                <tr>
                                                    <td colspan="10"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="text-overlap position-absolute">
                                            <h3 className="h5 pt-4 text-danger"><strong>Please Subscribe to see Today Calls</strong></h3>
                                            <p className="d-block">Already subscribed  <Link to="" className="text blogin text-success"><strong>Click here</strong></Link></p>
                                        </div>
                                    </div>
                                    {/*<div className="subscribe-box">*/}
                                    {/*    <div className="row">*/}
                                    {/*        <div className="col-xl-6">*/}
                                    {/*            <img alt="subscription image" className="float-xl-right mr-3" src={Subscribe} width="100" />*/}
                                    {/*        </div>*/}
                                    {/*        <div className="col-xl-6">*/}
                                    {/*            <h3 className="h5 pt-4 text-danger"><strong>Please Subscribe to see Today Calls</strong></h3>*/}
                                    {/*            <p className="d-block">Already subscribed  <Link to="/TodayCalls" className="text-success"><strong>click here</strong></Link></p>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>

                            </> :
                                <>
                                    <p>&nbsp;</p>

                                </>

                            }
                            <div className="col-xl-12 text-center pb-3">

                                <ins class="adsbygoogle"
                                    style={{ display: "block" }}
                                    data-ad-client="ca-pub-4276578180024665"
                                    data-ad-slot="8259893465"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                            <div className="col-xl-8">
                                <div className="row">
                                    <h3 class="h3 pb-2"><strong>Quick Bytes</strong></h3>
                                    {contents}
                                    <div className="col-12"></div>
                                    {/*<div className="col-xl-4 text-center"><img className="center-block mx-auto pb-4" src={addImage} width="350" alt="ads image" /></div>*/}
                                    {/*<div className="col-xl-4 text-center"><img className="center-block mx-auto pb-4" src={addImage} width="350" alt="ads image" /></div>*/}
                                    {/*<div className="col-xl-4 text-center"><img className="center-block mx-auto pb-4" src={addImage} width="350" alt="ads image" /></div>*/}
                                    
                                </div>
                                <div className="col-xl-12 pb-3 text-center">
                                    {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4276578180024665" crossorigin="anonymous"></script>*/}
                                    {/*<ins class="adsbygoogle"*/}
                                    {/*    style={{ display: "block" }}*/}
                                    {/*    data-ad-client="ca-pub-4276578180024665"*/}
                                    {/*    data-ad-slot="9900973965"*/}
                                    {/*    data-ad-format="auto"*/}
                                    {/*    data-full-width-responsive="true"></ins>*/}
                                    <AdSense.Google
                                        client='ca-pub-4276578180024665'
                                        slot='9900973965'
                                        style={{ display: 'block', height:'215' }}
                                        format='auto'
                                        responsive='true'
                                        layoutKey='-gw-1+2a-9x+5c'
                                    />
                                </div>
                                {/* <img src={BannerImage} alt="banner image" className="img-responsive" width="100%" height="auto" /> */}
                                <div className="pb-4">
                                    <h3 className="h3"><strong>News</strong></h3>
                                    <h6 className="pt-2 pb-2">Latest share market and India stock market news and updates.</h6>
                                    <hr />
                                    <div className='row mt-3'>
                                        {contents1}
                                    </div>
                                </div>
                                <div className="col-xl-12 text-center pt-3">
                                    <AdSense.Google
                                        client='ca-pub-4276578180024665'
                                        slot='9900973965'
                                        style={{ display: 'block', height: '300' }}
                                        responsive='true'
                                        layoutKey='-gw-1+2a-9x+5c'
                                    />
                                </div>
                                {/*<div className="col-xl-12 text-center mt-5"><img className="center-block mx-auto pb-4" src={addImage} width="100%" alt="ads image" /></div>*/}
                            </div>

                            <div className="col-xl-4">
                                <div className="text-center pt-3 pb-3">
                                    <ins class="adsbygoogle"
                                        style={{ display: "block" }}
                                        data-ad-client="ca-pub-4276578180024665"
                                        data-ad-slot="8259893465"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                </div>

                               

                                <div className="box pt-0">
                                    <h5 className="h5"><strong>Market Action</strong></h5>
                                    <hr />

                                    {/*<div class="tradingview-widget-container pt-2">*/}
                                    {/*    <div class="tradingview-widget-container__widget"></div>*/}
                                    {/*    <div class="tradingview-widget-copyright" id="makeaction">*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="canvas_block" id="myContainer">
                                        <div className="canvas_block_img" >
                                            <div className="tradingview-widget-container">
                                            </div>
                                        </div>
                                    </div>

                                   
                                    {/*<div id="indices_price" ind_id="9" suggested_name="Nifty 50" className="clearfix txt14_bb UC">*/}
                                    {/*    <span id="name">Nifty 50</span>*/}
                                    {/*    <span id="cp" className="ML15">18,420.45</span>*/}
                                    {/*    <span id="arrow_class" className="green_arw"></span>*/}
                                    {/*    <span id="chg" className="grntxt">151.45</span>*/}
                                    {/*    <span id="percentchange" className="fn_smalltxt grntxt">(0.83%)</span>*/}
                                    {/*</div>*/}
                                    {/*<div id="advance_decline_unchange" className=" PB10 txt12 clearfix">*/}
                                    {/*    <div className="FL MR10"><span className="green_arw"></span> Adv <span id="advances">41</span></div>*/}
                                    {/*    <div className="FL MR10"><span className="red-arw"></span> Decl <span id="declines">9</span></div>*/}
                                    {/*    <div className="FL MR10"></div>*/}
                                    {/*</div>*/}
                                    {/*<iframe className="ifr" title="Indices Chart" width="90%" height="120" frameborder="0" src="https://www.moneycontrol.com/mccode/common/indices_chart/indices_chart.php?classic=true&amp;market=i&amp;period=1d&amp;ind_id=9&amp;width=250&amp;height=120&amp;classic=true"></iframe>*/}
                                    {/*<ul className="marctcNv nav-tabs">*/}
                                    {/*    <li className="active"><Link href="#mac1d" title="1 day" reload_div="mac1d" period="1d" element="market_action_chart_tabs">1D</Link></li>*/}
                                    {/*    <li><Link href="#mac1d" title="5 days" reload_div="mac1d" period="5" element="market_action_chart_tabs">5D</Link></li>*/}
                                    {/*    <li><Link href="#mac1d" title="1 month" reload_div="mac1d" period="30" element="market_action_chart_tabs">1M</Link></li>*/}
                                    {/*    <li><Link href="#mac1d" title="3 months" reload_div="mac1d" period="90" element="market_action_chart_tabs">3M</Link></li>*/}
                                    {/*    <li><Link href="#mac1d" title="6 months" reload_div="mac1d" period="180" element="market_action_chart_tabs">6M</Link></li>*/}
                                    {/*    <li><Link href="#mac1d" title="1 year" reload_div="mac1d" period="365" element="market_action_chart_tabs">1Y</Link></li>*/}
                                    {/*    <li><Link href="#mac1d" title="2 years" reload_div="mac1d" period="730" element="market_action_chart_tabs">2Y</Link></li>*/}
                                    {/*</ul>*/}
                                    <div className="col-12"><p>&nbsp;</p></div>
                                    <div className="text-center pb-3">
                                        <AdSense.Google
                                            client='ca-pub-4276578180024665'
                                            slot='9900973965'
                                            style={{ display: 'block', height: '200' }}
                                            format='auto'
                                            responsive='true'
                                            layoutKey='-gw-1+2a-9x+5c'
                                        />
                                    </div>
                                    <table className="table table-sm mb-0 borderless">
                                        <thead className="d-none"><tr><th></th></tr></thead>
                                        <tbody>
                                            <tr>
                                                <td className="font-bold h5 border-0 pb-2">
                                                    <h5 className='h5'><strong>Top Gainers & Losers</strong></h5>
                                                </td>
                                                <td className="float-right">
                                                    {/*<select className="form-control form-control-sm">*/}
                                                    {/*    <option value="1">Nify</option>*/}
                                                    {/*    <option value="1">NSE</option>*/}
                                                    {/*</select>*/}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="TOP_GN_DATA" role="tabpanel" aria-labelledby="TOP_GN_NAV">
                                            {/*<div class="tradingview-widget-container">*/}
                                            {/*    <div class="tradingview-widget-container__widget"></div>*/}
                                            {/*    <div class="tradingview-widget-copyright" id="topgainers1">*/}
                                                  
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="tradingview-widget-container" ref={this.myRef}>
                                                <div className="tradingview-widget-container__widget"></div>
                                            </div>



                                            {/*<table cellpadding="0" cellspacing="0" width="100%" className="rhsglTbl">*/}
                                            {/*    <thead className="bg-transparent border-top border-bottom">*/}
                                            {/*        <tr>*/}
                                            {/*            <th className="pt-2 pb-2 font-normal">Company</th>*/}
                                            {/*            <th className="font-normal">Price</th>*/}
                                            {/*            <th className="font-normal">Change</th>*/}
                                            {/*            <th className="font-normal">%Gain</th>*/}
                                            {/*        </tr>*/}
                                            {/*    </thead>*/}
                                            {/*    <tbody>*/}
                                            {/*        <tr>*/}
                                            {/*            <td className="font-bold">Britannia</td>*/}
                                            {/*            <td className="font-bold"><b>4,465.45</b></td>*/}
                                            {/*            <td className="text-success">55.75</td>*/}
                                            {/*            <td className="text-success">1.26</td>*/}
                                            {/*        </tr>*/}

                                            {/*        <tr>*/}
                                            {/*            <td className="font-bold">*/}
                                            {/*                Hero Motocorp*/}
                                            {/*            </td>*/}
                                            {/*            <td className="font-bold"><b>2,770.85</b></td>*/}
                                            {/*            <td className="text-success">23.50</td>*/}
                                            {/*            <td className="text-success">0.86</td>*/}
                                            {/*        </tr>*/}

                                            {/*        <tr>*/}
                                            {/*            <td className="font-bold">*/}
                                            {/*                SBI Life Insura*/}
                                            {/*            </td>*/}
                                            {/*            <td className="font-bold"><b>1,273.50</b></td>*/}
                                            {/*            <td className="text-success">3.75</td>*/}
                                            {/*            <td className="text-success">0.30</td>*/}
                                            {/*        </tr>*/}

                                            {/*        <tr>*/}
                                            {/*            <td className="font-bold">NTPC*/}
                                            {/*            </td>*/}
                                            {/*            <td className="font-bold"><b>172.10</b></td>*/}
                                            {/*            <td className="text-success">0.50</td>*/}
                                            {/*            <td className="text-success">0.29</td>*/}
                                            {/*        </tr>*/}

                                            {/*        <tr>*/}
                                            {/*            <td className="font-bold">*/}
                                            {/*                Sun Pharma*/}
                                            {/*            </td>*/}
                                            {/*            <td className="font-bold"><b>1,002.20</b></td>*/}
                                            {/*            <td className="text-success">1.95</td>*/}
                                            {/*            <td className="text-success">0.19</td>*/}
                                            {/*        </tr>*/}
                                            {/*    </tbody>*/}
                                            {/*</table>*/}
                                            {/*<Link href="javascript:;" className="small-text small-btn mt-2 text-dark">&nbsp;+ See all Top Gainers&nbsp;</Link>*/}
                                        </div>
                                        <div className="tab-pane fade" id="TOP_GB_DATA" role="tabpanel" aria-labelledby="TOP_GB_NAV">
                                            <table className="table table-hover table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>Stocks</th>
                                                        <th>Price</th>
                                                        <th>Change</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="TOP_GB"><tr><td><b><Link target="_blank" href="https://www.equitypandit.com/share-price/BAJFINANCE">BAJFINANCE</Link></b></td><td> 6,675.70</td><td><b className="gn_bse"> +23.20 (0.35%) <i className="fa fa-caret-up"></i></b></td></tr><tr><td><b><Link target="_blank" href="https://www.equitypandit.com/share-price/M&amp;M">M&amp;M</Link></b></td><td> 1,288.45</td><td><b className="gn_bse"> +2.30 (0.18%) <i className="fa fa-caret-up"></i></b></td></tr><tr><td><b><Link target="_blank" href="https://www.equitypandit.com/share-price/NTPC">NTPC</Link></b></td><td> 172.15</td><td><b className="gn_bse"> +0.20 (0.12%) <i className="fa fa-caret-up"></i></b></td></tr><tr><td ><b><Link target="_blank" href="https://www.equitypandit.com/share-price/SUNPHARMA">SUNPHARMA</Link></b></td><td> 1,000.95</td><td><b className="gn_bse"> +1.00 (0.10%) <i className="fa fa-caret-up"></i></b></td></tr></tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/*<div className="col-12 clearfix"><p>&nbsp;</p></div>*/}
                                    {/*<table className="table table-sm mb-0 borderless mt-3">*/}
                                    {/*    <thead className="d-none"><tr><th></th></tr></thead>*/}
                                    {/*    <tbody>*/}
                                    {/*        <tr>*/}
                                    {/*            <td className="font-bold h5 border-0 pb-2">*/}
                                    {/*                <h5 className='h5'><strong>Top Losers</strong></h5>*/}
                                    {/*            </td>*/}
                                    {/*            <td className="float-right">*/}
                                    {/*                <select className="form-control form-control-sm">*/}
                                    {/*                    <option value="1">Nify</option>*/}
                                    {/*                    <option value="1">NSE</option>*/}
                                    {/*                </select>*/}
                                    {/*            </td>*/}
                                    {/*        </tr>*/}
                                    {/*    </tbody>*/}
                                    {/*</table>*/}
                                    {/*<div className="tab-content" id="pills-tabContent">*/}
                                    {/*    <div className="tab-pane fade show active" id="TOP_LN_DATA" role="tabpanel" aria-labelledby="TOP_LN_NAV">*/}
                                    {/*        <table className="table table-hover table-sm mb-0">*/}
                                    {/*            <thead className="bg-transparent border-top border-bottom">*/}
                                    {/*                <tr>*/}
                                    {/*                    <th className="pt-2 pb-2 font-normal">Company</th>*/}
                                    {/*                    <th className="font-normal">Price</th>*/}
                                    {/*                    <th className="font-normal">Change</th>*/}
                                    {/*                    <th className="font-normal">%Gain</th>*/}
                                    {/*                </tr>*/}
                                    {/*            </thead>*/}
                                    {/*            <tbody>*/}
                                    {/*                <tr>*/}
                                    {/*                    <td className="font-bold">TCS</td>*/}
                                    {/*                    <td className="font-bold">3,202.05</td>*/}
                                    {/*                    <td className="rdtxt">-38.20</td>*/}
                                    {/*                    <td className="rdtxt">-1.18</td>*/}
                                    {/*                </tr>*/}


                                    {/*                <tr>*/}
                                    {/*                    <td className="font-bold">*/}
                                    {/*                        Infosys*/}
                                    {/*                    </td>*/}
                                    {/*                    <td className="font-bold"><b>1,507.35</b></td>*/}
                                    {/*                    <td className="rdtxt">-14.85</td>*/}
                                    {/*                    <td className="rdtxt">-0.98</td>*/}
                                    {/*                </tr>*/}


                                    {/*                <tr>*/}
                                    {/*                    <td className="font-bold">*/}
                                    {/*                        ONGC*/}
                                    {/*                    </td>*/}
                                    {/*                    <td className="font-bold"><b>145.80</b></td>*/}
                                    {/*                    <td className="rdtxt">-1.40</td>*/}
                                    {/*                    <td className="rdtxt">-0.95</td>*/}
                                    {/*                </tr>*/}


                                    {/*                <tr>*/}
                                    {/*                    <td className="font-bold">*/}
                                    {/*                        Tata Motors*/}
                                    {/*                    </td>*/}
                                    {/*                    <td className="font-bold"><b>418.00</b></td>*/}
                                    {/*                    <td className="rdtxt">-3.60</td>*/}
                                    {/*                    <td className="rdtxt">-0.85</td>*/}
                                    {/*                </tr>*/}


                                    {/*                <tr>*/}
                                    {/*                    <td className="font-bold">*/}
                                    {/*                        Sun Pharma*/}
                                    {/*                    </td>*/}
                                    {/*                    <td className="font-bold"><b>987.95</b></td>*/}
                                    {/*                    <td className="rdtxt">-5.70</td>*/}
                                    {/*                    <td className="rdtxt">-0.57</td>*/}
                                    {/*                </tr>*/}
                                    {/*            </tbody>*/}
                                    {/*        </table>*/}
                                    {/*        <Link href="javascript:;" className="small-text small-btn mt-2 text-dark">&nbsp;+ See all Top Losers&nbsp;</Link>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="tab-pane fade" id="TOP_LB_DATA" role="tabpanel" aria-labelledby="TOP_LB_NAV">*/}
                                    {/*        <table className="table table-hover table-sm">*/}
                                    {/*            <thead>*/}
                                    {/*                <tr>*/}
                                    {/*                    <th>Stocks</th>*/}
                                    {/*                    <th>Price</th>*/}
                                    {/*                    <th>Change</th>*/}
                                    {/*                </tr>*/}
                                    {/*            </thead>*/}
                                    {/*            <tbody>*/}


                                    {/*                <tr>*/}
                                    {/*                    <td>*/}
                                    {/*                        <Link href="https://www.moneycontrol.com/india/stockpricequote/infrastructure-general/adaniportsspecialeconomiczone/MPS" title="Adani Ports" onclick="GAEventTracker( 'Stock Action_HP', 'Click', 'India_Top Gainers_Nifty_Adani Ports' );">Adani Ports</Link>*/}
                                    {/*                    </td>*/}
                                    {/*                    <td><b>892.85</b></td>*/}
                                    {/*                    <td className="grntxt">32.40</td>*/}
                                    {/*                    <td className="grntxt">3.77</td>*/}
                                    {/*                </tr>*/}


                                    {/*                <tr>*/}
                                    {/*                    <td>*/}
                                    {/*                        <Link href="https://www.moneycontrol.com/india/stockpricequote/auto-cars-jeeps/mahindramahindra/MM" title="M&amp;M" onclick="GAEventTracker( 'Stock Action_HP', 'Click', 'India_Top Gainers_Nifty_M&amp;M' );">M&amp;M</Link>*/}
                                    {/*                    </td>*/}
                                    {/*                    <td><b>1,290.65</b></td>*/}
                                    {/*                    <td className="grntxt">39.05</td>*/}
                                    {/*                    <td className="grntxt">3.12</td>*/}
                                    {/*                </tr>*/}


                                    {/*                <tr>*/}
                                    {/*                    <td>*/}
                                    {/*                        <Link href="https://www.moneycontrol.com/india/stockpricequote/auto-lcvs-hcvs/eichermotors/EM" title="Eicher Motors" onclick="GAEventTracker( 'Stock Action_HP', 'Click', 'India_Top Gainers_Nifty_Eicher Motors' );">Eicher Motors</Link>*/}
                                    {/*                    </td>*/}
                                    {/*                    <td><b>3,388.90</b></td>*/}
                                    {/*                    <td className="grntxt">97.45</td>*/}
                                    {/*                    <td className="grntxt">2.96</td>*/}
                                    {/*                </tr>*/}


                                    {/*                <tr>*/}
                                    {/*                    <td>*/}
                                    {/*                        <Link href="https://www.moneycontrol.com/india/stockpricequote/power-generation-distribution/powergridcorporationindia/PGC" title="Power Grid Corp" onclick="GAEventTracker( 'Stock Action_HP', 'Click', 'India_Top Gainers_Nifty_Power Grid Corp' );">Power Grid Corp</Link>*/}
                                    {/*                    </td>*/}
                                    {/*                    <td><b>218.90</b></td>*/}
                                    {/*                    <td className="grntxt">5.70</td>*/}
                                    {/*                    <td className="grntxt">2.67</td>*/}
                                    {/*                </tr>*/}


                                    {/*                <tr>*/}
                                    {/*                    <td>*/}
                                    {/*                        <Link href="https://www.moneycontrol.com/india/stockpricequote/trading/adanienterprises/AE13" title="Adani Enterpris" onclick="GAEventTracker( 'Stock Action_HP', 'Click', 'India_Top Gainers_Nifty_Adani Enterpris' );">Adani Enterpris</Link>*/}
                                    {/*                    </td>*/}
                                    {/*                    <td><b>4,075.30</b></td>*/}
                                    {/*                    <td className="grntxt">94.50</td>*/}
                                    {/*                    <td className="grntxt">2.37</td>*/}
                                    {/*                </tr>*/}
                                    {/*            </tbody>*/}
                                    {/*        </table>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <p></p>

                                </div>
                            </div>



                        </div>
                    </div>
                 
                </div>

                <div id="popupp" className="overlayy">
                    <div className="popupp">
                        <Link className="close" to="#">&times;</Link>
                        <div className="content">
                            {contents2}
                        </div>
                    </div>
                </div>
            </div>





        );
    }
    async populateNews() {
        ReactSession.setStoreType("localStorage");
        //this.setState({ loading: false });

        const response2 = await fetch('/api/Values/GetNews');
        if (response2.status == '401') {
            this.props.history.push("/");
        }
        else {
            const data = await response2.json();
            this.setState({ NewsData: data.data, loading: false });
        }



        const response1 = await fetch('/api/Values/GetINews');
        if (response1.status == '401') {
            this.props.history.push("/");
        }
        else {
            const data1 = await response1.json();
            this.setState({ NewsIData: data1.data, loading: false });
        }
    }
}
