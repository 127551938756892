import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu';
import Logo from './wp-content/uploads/2021/logo/logo.png';
import zonlogo from './wp-content/uploads/2021/logo/zon-logo.png';

export class Footer extends Component {
  static displayName = Footer.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render () {
      return (
          <div className="footer pb-5">
            
              <div className="w-full relative" id="site-footer">
                  <div className="max-w-screen-xl px-32px py-32px sm:py-80px mx-auto / flex flex-col md:flex-row md:items-center">
                      
                      <div className="md:h-40px / flex flex-col md:flex-row items-center">
                          <Link className="md:mr-8" to="/">
                              <img src={ Logo} alt="Logo image" />
                          </Link>
                          <div className="d-xl-none d-lg-none d-md-none d-sm-flex d-flex md:h-40px md:ml-auto / mt-16px md:mt-0 / flex flex-col md:flex-row items-center xs-full">
                              <Link to="/aboutus" className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" href="javascript:;">
                                  <span className="text-sm">About</span>
                              </Link>

                              <Link to="/privacypolicy" className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" href="javascript:;">
                                  <span className="text-sm">Privacy</span>
                              </Link>

                              <Link to="/contact" className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" href="contact-us.html">
                                  <span className="text-sm">Contact</span>
                              </Link>
                          </div>
                          <div className="md:flex lg:items-center mt-8px md:mt-0">
                              <p className="h-40px px-16px  items-center center-mobi">
                                  <span className="text-sm center-mobi">Copyright © 2023 Lucros Solutions PVT LTD. All rights reserved.</span><br />
                                  <span className="flex center-mobi">Design & Developed by&nbsp;<img src={zonlogo} alt="zon logo" className="mt-2" style={{ width: "15px", height: "15px" }} />&nbsp; Zon Innovative.</span>
                              </p>
                          </div>
                      </div>

                      <div className="d-xl-flex d-lg-flex d-md-flex d-sm-none d-none md:h-40px md:ml-auto / mt-16px md:mt-0 / flex flex-col md:flex-row items-center xs-full">
                          <Link to="/aboutus" className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" href="javascript:;">
                              <span className="text-sm">About</span>
                          </Link>

                          <Link to="/privacypolicy" className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" href="javascript:;">
                              <span className="text-sm">Privacy</span>
                          </Link>

                          <Link to="/contact" className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" href="contact-us.html">
                              <span className="text-sm">Contact</span>
                          </Link>
                      </div>
                  </div>
              </div>
          </div>
           
                  
    );
  }
}
