import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';
import Subscribe from './wp-content/uploads/2021/subscribe.gif';

import { Link } from 'react-router-dom';

export class TodayCalls extends Component {
    static displayName = TodayCalls.name;
    constructor(props) {
        super(props);
        this.state = { TodayCallsData: [], loading: true };
       
    }

    componentDidMount() {
        this.populateTodayCalls();
    }
    refreshData = (data) => {
        this.populateTodayCalls();
    };
    renderTodayCallsTable(TodayCallsData, refreshData) {
        var $today = new Date();
        var $yesterday = new Date($today);
        var subdate = new Date(ReactSession.get("SubscriptionEndDate"));
        if (subdate >= $yesterday) {
            return (

                <div className="table-resposive" style={{display:"block", overflow:"auto", width:"100%"}}>
                <table className="table table-bordered position-relative">
                    <thead>
                        <tr>
                            <th scope="col">Index Stock</th>
                            <th scope="col">Trade Type</th>
                            <th scope="col">Stock</th>
                            <th scope="col">Call Type</th>
                            <th scope="col">Price</th>
                            <th scope="col">Target -1</th>
                            <th scope="col">Target -2</th>
                            <th scope="col">Target -3</th>
                            <th scope="col">SL</th>
                            <th scope="col">Total Gain</th>
                        </tr>
                    </thead>
                    <tbody>
                        {TodayCallsData.length == 0 ? <tr><td align="center" valign="middle" colSpan="10">No Stock Call Data</td></tr> : TodayCallsData.map(w =>

                            <tr key={w.call_Id}>
                                <td align="center" valign="middle">
                                    {w.stock_Index_Id}
                                </td>
                                <td align="center" valign="middle">
                                    {w.trade_Id}
                                </td>
                                <td align="center" valign="middle" className="left sorting_1">
                                    <span className="full_grid">
                                        <span className="toolTip half_grid ng-isolate-scope" pm-tool-tip="" tip-direction="right" index="8" href="javascript:void(0)">
                                            <b>{w.stockName}</b>
                                        </span>
                                    </span>
                                </td>
                                <td align="center" valign="middle">
                                    {w.type_Id == 1 ? <a href="javascript:void(0)" className="buy">Buy </a> : <a href="javascript:void(0)" className="sell">Sell </a>}
                                </td>
                                {/*<td>*/}

                                {/*</td>*/}
                                <td>
                                    {w.price}
                                </td>
                                <td>
                                    {/*{w.trans_type == 1 ? '' + { w.trans_amount } +'' : '0'}*/}
                                    {w.t1}
                                </td>
                                <td>
                                    {w.t2==0?"N/A":w.t2}


                                </td>
                                <td>
                                    {w.t3 == 0 ? "N/A" : w.t3}

                                </td>
                                <td>
                                    {w.sl == 0 ? "N/A" : w.sl}
                                </td>
                                <td>

                                    {w.gain == 0 ? 'N/A' : w.gain}
                                </td>
                            </tr>
                        )}

                    </tbody>
                    </table>
                </div>
            );
        }
        else {
            return (
                <div className="col-12">
                    <div className="subscribe-box">
                        <img alt="subscription image" className="img-responsive mr-3 d-inline" src={Subscribe} width="100" />
                        <h3 className="d-inline h6"><strong>Please Subscribe to see Today Calls</strong></h3>
                    </div>
                </div>
            );
        }
    }
    render() {
        ReactSession.setStoreType("localStorage");

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderTodayCallsTable(this.state.TodayCallsData, this.refreshData);
        return (
            <div>
                <div className="w-screen / py-18px sm:py-100px lg:py-100px / relative / animate-fadein animation-delay-500ms / gutenberg">
                    <p></p>
                    <div className="max-w-screen-xl  / px-32px / mx-auto mt-28px sm:mt-48px / relative z-10 mobiscreen">
                        <div className="text-center">
                            <h1>Today Calls</h1>
                            <span>Get the tiger's eye view on short & medium term trends,<br />  along with alerts & updates!</span>
                        </div>
                       
                        <p></p>
                        
                      

                        <div className="mx-auto subscription ">
                            <div className="col100 mt4  flex-align-center">
                                <h6><strong>Today</strong></h6>
                                <div className="padd-0-mobi bg-white / px-48px sm:px-16px md:px-32px xl:px-32px py-32px / rounded-8px sm:rounded-none md:rounded-8px / shadow-xl sm:shadow-none md:shadow-2xl / flex flex-col text-center">
                                    {contents}
                                </div>
                            </div>
                        </div>
                        <div className='text-right pt-4'>
                            <Link to='/previouscalls' className='text-right text-dark h6'>
                                See Previous Calls <i className='fa fa-angle-double-right'></i>
                            </Link>
                        </div>

                    </div>






                    <div className="w-full / absolute bottom-1/2 left-0">
                        <div className="max-w-screen-xl / mx-auto / relative">


                            <svg className="w-80pc md:w-50pc / absolute bottom-0 right-95pc / animate-float-15" viewBox="0 0 697 663" fill="none" xmlns="http://www.w3.org/2000/svg">
                                {/* <path d="M697 565C697 555.983 693.523 547.335 687.335 540.958C681.146 534.582 672.752 531 664 531C655.248 531 646.854 534.582 640.665 540.958C634.477 547.335 631 555.983 631 565L664 565H697Z" fill="#1a9926" /> */}
                                <ellipse cx="324.5" cy="331.5" rx="324.5" ry="331.5" fill="#124d99" />
                            </svg>



                        </div>
                    </div>


                    <div className="w-full / absolute bottom-0 left-0">
                        <div className="max-w-screen-xl / mx-auto / relative">

                            <svg className="w-40pc md:w-20pc / absolute bottom-0 right-90pc / animate-float-15" viewBox="0 0 227 169" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M34 75C24.9826 75 16.3346 71.4179 9.95837 65.0416C3.58213 58.6654 -2.50846e-07 50.0174 0 41C2.50846e-07 31.9826 3.58213 23.3346 9.95837 16.9584C16.3346 10.5821 24.9826 7 34 7L34 41L34 75Z" fill="#124d99" />
                                <ellipse cx="144" cy="84.5" rx="83" ry="84.5" fill="#1a9926" />
                            </svg>


                        </div>
                    </div>
                </div>
            </div>
        );
    }
    async populateTodayCalls() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/api/Values/GetTodayCalls', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status == '401') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            this.setState({ TodayCallsData: data.data, loading: false });
        }
    }
}
