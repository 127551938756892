import React, { Component } from 'react';
import {  BrowserRouter, Switch, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
// import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
/*import { Counter } from './components/Counter';*/
import { Dashboard } from './components/Dashboard';
import { Login } from './components/Account/Login';
import { Register } from './components/Account/Register';
import { TodayCalls } from './components/TodayCalls';
import { PreviousCalls } from './components/PreviousCalls';
import { NewsDetails } from './components/NewsDetails';
import { AboutUs } from './components/AboutUs';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { Contact } from './components/Contact';

// import {AccLayout} from './components/AccountLayout/AccLayout';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route path='/fetch-data' component={FetchData} />
                <Route exact path='/' component={Dashboard} />
                <Route path='/todaycalls' component={TodayCalls} />
                <Route path='/previouscalls' component={PreviousCalls} />
                <Route path='/aboutus' component={AboutUs} />
                <Route path='/privacypolicy' component={PrivacyPolicy} />
                <Route path='/contact' component={Contact} />
                <Route path='/Account/login' component={Login} />
                <Route path='/Account/register' component={Register} />
                <Route path='/News/:news_Id' component={NewsDetails} />
            </Layout>

        );
    }
}