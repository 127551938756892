import React, { Component } from 'react';
import { ReactSession } from 'react-client-session';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import Logo from './wp-content/uploads/2021/logo/logo.png';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.state = { Email: "", Password: "", Message: "", Ip: "", collapsed: true,isscriptloaded:false };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.myRef = React.createRef();
    }
    componentDidMount() {
        const script5 = document.createElement('script');
        script5.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
        script5.async = false;
        script5.innerHTML = JSON.stringify({
            "symbols": [
                {
                    "proName": "FOREXCOM:SPXUSD",
                    "title": "S&P 500"
                },
                {
                    "proName": "FOREXCOM:NSXUSD",
                    "title": "US 100"
                },
                {
                    "proName": "FX_IDC:EURUSD",
                    "title": "EUR/USD"
                },
                {
                    "proName": "BITSTAMP:BTCUSD",
                    "title": "Bitcoin"
                },
                {
                    "proName": "BITSTAMP:ETHUSD",
                    "title": "Ethereum"
                }
            ],
            "showSymbolLogo": true,
            "colorTheme": "light",
            "isTransparent": false,
            "displayMode": "adaptive",
            "locale": "en"
        })
        this.myRef.current.appendChild(script5);
    }
    signOutClick() {
        localStorage.clear();
        window.location.href = "/"
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }
    showpass = (e) => {
      
        var x = document.getElementById("Password");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();

        if (this.validate()) {
            try {
                fetch('/api/values/login', {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: "POST",
                    body:JSON.stringify({
                        Email: this.state.Email,
                        Password: this.state.Password
                    }),
                }).then((response) => {
                    return response.json();
                })
                    .then((jsonData) => {
                        this.setState({ Message: jsonData.message });
                        console.log(jsonData.data[0].token);


                        if (jsonData.isSuccess) {
                            ReactSession.setStoreType("localStorage");
                            ReactSession.set("login_datetime", new Date());
                            ReactSession.set("User_ID", jsonData.data[0].id);
                            ReactSession.set("session_user", jsonData.data[0].username);
                            ReactSession.set("Full_Name", jsonData.data[0].full_Name);
                            ReactSession.set("token", jsonData.data[0].token);
                            ReactSession.set("MobileNo", jsonData.data[0].mobileNo);
                            ReactSession.set("SubscriptionEndDate", jsonData.data[0].subscriptionEndDate);
                            //this.props.history.push("/todaycalls");
                            window.location.href = "/todaycalls"
                        }
                        else {
                            //alert(jsonData.message);

                        }
                    });
            } catch (err) {
                console.log(err);
            }
        }


    }
    validate() {
        let messageError = "";
        if (!this.state.Email) {
            messageError = "Phone Number is required";
        }
        else if (!this.state.Password) {
            messageError = "Password is required";
        }
        if (messageError) {
            this.setState({ Message: messageError });
            return false;
        }
        else {
            this.setState({ Message: "" });

        }
        return true;
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    
    render() {
  
        setTimeout(function () {
            $('.text').on("click", function () {
                $('.popup').css('opacity', 1);
                $('.overlay').css('opacity', 0.8);
                $('.popup').css('display', 'block');
                $('.overlay').css('display', 'block');
            });
            // close popup
            $('.popup-close').on("click", function () {
                $('.popup').css('opacity', 0);
                $('.overlay').css('opacity', 0);
                setTimeout(function () {
                    $('.popup').css('display', 'none');
                    $('.overlay').css('display', 'none');
                }, 10);

            });
            $(".afotp").hide();
            //    $("#send").on("click",function(){
            //        $(".botp").hide();
            //        $(".afotp").show();
            //});

            // $('#mobile-toggle').click(function () {
            //     if ($("#mobile-menu").hasClass('mobile-active')) {
            //         $("#mobile-menu").addClass('mobile-active');
            //     }
            //     else {
            //         $("#mobile-menu").addClass('mobile-active');
            //     }
            // })
            // $("#mobile-toggle").click(function () {
            //     $(".mobile-screen").hide();
            // });
            $('#mobile-toggle').click(function () {
                $("#mobile-menu").toggleClass()('mobile-active');
            });
        }, 1);
        if (!this.state.isscriptloaded) {

            $(document).ready(function ($) { 


              


        const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
            script.async = true;
            $(script).append(JSON.stringify({
                "symbols": [
                    {
                        "proName": "FOREXCOM:SPXUSD",
                        "title": "S&P 500"
                    },
                    {
                        "proName": "FOREXCOM:NSXUSD",
                        "title": "US 100"
                    },
                    {
                        "proName": "FX_IDC:EURUSD",
                        "title": "EUR/USD"
                    },
                    {
                        "proName": "BITSTAMP:BTCUSD",
                        "title": "Bitcoin"
                    },
                    {
                        "proName": "BITSTAMP:ETHUSD",
                        "title": "Ethereum"
                    }
                ],
                "showSymbolLogo": true,
                "colorTheme": "light",
                "isTransparent": false,
                "displayMode": "adaptive",
                "locale": "en"
            }

        ));
            $('#tradenews').append(script);
        })
            this.setState({ isscriptloaded: true });
        }


        ReactSession.setStoreType("localStorage");
        
        return (
            <div className="header">
                {/* desktop menu*/}
                <div className="w-screen / duration-150 / z-50 / animate-fadeindown animation-delay-250ms" id="site-header">
                    <div className="max-w-screen-xl px-32px py-24px mx-auto / flex items-center">
                        <div className="h-40px / flex items-center">
                            <Link className="lg:mr-32px" to="/">
                                <img src={Logo} alt="Logo image" />
                            </Link>

                            <div className="hidden lg:flex lg:items-center">

                                {ReactSession.get("token") == undefined ? <>
                                    <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="/todaycalls">
                                        <span className="text-14px">Home</span>
                                    </Link>
                                    <Link className="h-40px px-16px rounded-full text-wtf-gunmetal / hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="/">
                                        <span className="text-14px">News</span>
                                    </Link>
                                    <Link className="h-40px px-16px rounded-full text-wtf-gunmetal / hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="/">
                                        <span className="text-14px">Business News</span>
                                    </Link>
                                    <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center btn-orange text-white mr-3" to="/previouscalls">
                                        <span className="text-14px"><i className="fa fa-phone-alt"></i> &nbsp;Previous Calls</span>
                                    </Link>
                                    {/* <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="#">
                                        <span className="text-14px">Companies</span>
                                    </Link>


                                    <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="#">
                                        <span className="text-14px">BSE</span>
                                    </Link>
                                    <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="#">
                                        <span className="text-14px">NSE</span>
                                    </Link>
                                    <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="#">
                                        <span className="text-14px">Stocks</span>
                                    </Link> */}
                                </> :
                                    <>
                                        <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="/todaycalls">
                                            <span className="text-14px">Home</span>
                                        </Link>
                                        <Link className="h-40px px-16px rounded-full text-wtf-gunmetal / hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="/">
                                            <span className="text-14px">News</span>
                                        </Link>
                                        <Link className="h-40px px-16px rounded-full text-wtf-gunmetal / hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="/">
                                            <span className="text-14px">Business News</span>
                                        </Link>
                                        <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center btn-orange text-white mr-3" to="/previouscalls">
                                            <span className="text-14px"><i className="fa fa-phone-alt"></i> &nbsp;Previous Calls</span>
                                        </Link>
                                        <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center border bg-success text-white" to="/TodayCalls">
                                            <span className="text-14px"><i className="fa fa-calendar"></i> Today Calls</span>
                                        </Link></>
                                }
                            </div>
                        </div>

                        <div className="h-40px ml-auto / flex items-center">
                            <div className="w-40px h-40px / lg:hidden items-center" action-type="toggle" action-id="part" action-data="mobile-menu" id="mobile-toggle">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.75 5.75H19.25" stroke="#141414" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9.75 18.25H19.25" stroke="#141414" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M4.75 12H19.25" stroke="#141414" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>


                            {ReactSession.get("token") == undefined ? <>
                                <Link className="hidden md:block h-40px px-24px rounded-full text-dark hover:text-wtf-majorelle-blue-hover text-white / transition duration-150 ease-in-out / flex items-center whitespace-nowrap text pt-2" to="/Account/Register">
                                 {/*   <span className="md:hidden hidden text-14px font-medium"><i className="fa fa-edit"></i> Register</span>*/}
                                    <span className="hidden md:block text-14px font-medium"><i className="fa fa-edit"></i> Register</span>
                                </Link>
                                <Link className="h-40px px-24px rounded-full bg-wtf-majorelle-green hover:bg-wtf-majorelle-blue-hover text-white / transition duration-150 ease-in-out / flex items-center whitespace-nowrap text" to="#">
                                    <span className="md:hidden text-14px font-medium"><i className="fa fa-sign-in"></i> Login</span>
                                    <span className="hidden md:block text-14px font-medium"><i className="fa fa-sign-in"></i> Login</span>
                                </Link>
                               
                            </> :
                                <div className="d-flex">
                                    <span className="mr-3"><i className="fa fa-user"></i> {ReactSession.get("Full_Name").substring(0, 10)}</span>
                                    <Link className="hidden md:block h-40px px-24px rounded-full bg-wtf-majorelle-green hover:bg-wtf-majorelle-blue-hover text-white / transition duration-150 ease-in-out / flex items-center whitespace-nowrap" to="#" onClick={this.signOutClick}>
                                    <span className="md:hidden hidden text-14px font-medium pt-2">LogOut <i className="fa fa-sign-out-alt"></i></span>
                                    <span className="hidden md:block text-14px font-medium pt-2">LogOut <i className="fa fa-sign-out-alt"></i></span>
                                </Link>
                               </div>
                            }
                            
                        </div>
                    </div>
                </div>
                {/*mobile menu*/}
                <div className="w-screen / bg-opacity-90 fixed top-72px z-50 / invisible opacity-0 / transition duration-500" id="mobile-menu" toggle-class-list="invisible opacity-0 animate-fadeindown">
                    {ReactSession.get("token") == undefined ? <>
                        <div className="w-full px-32px / flex">
                            <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="/todaycalls">
                                <span className="text-14px">Home</span>
                            </Link>
                            <Link className="h-40px px-16px rounded-full text-wtf-gunmetal shadow-sm /  bg-wtf-cultured / justify-center ml-auto / flex items-center" to="/">
                                <span className="text-sm">News</span>
                            </Link>
                            <Link className="h-40px px-16px rounded-full text-wtf-gunmetal shadow-sm / bg-wtf-cultured / justify-center / ml-8px / flex items-center" to="/">
                                <span className="text-sm">Business News</span>
                            </Link>
                            <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center btn-orange text-white mr-3" to="/previouscalls">
                                <span className="text-14px">Previous Calls</span>
                            </Link>
                           
                            {/* <Link className="h-40px px-16px rounded-full text-wtf-gunmetal shadow-sm /  bg-wtf-cultured / justify-center ml-8px / flex items-center" to="#">
                            <span className="text-sm">Companies</span>
                        </Link>
                        <Link className="h-40px px-16px rounded-full text-wtf-gunmetal shadow-sm /  bg-wtf-cultured / justify-center ml-8px / flex items-center" to="#">
                            <span className="text-sm">Technology</span>
                        </Link>
                        <Link className="h-40px px-16px rounded-full text-wtf-gunmetal shadow-sm /  bg-wtf-cultured / justify-center ml-8px / flex items-center" to="#">
                            <span className="text-sm">BSE</span>
                        </Link>
                        <Link className="h-40px px-16px rounded-full text-wtf-gunmetal shadow-sm /  bg-wtf-cultured / justify-center ml-8px / flex items-center" to="#">
                            <span className="text-sm">NSE</span>
                        </Link>
                        <Link className="h-40px px-16px rounded-full text-wtf-gunmetal shadow-sm /  bg-wtf-cultured / justify-center ml-8px / flex items-center" to="#">
                            <span className="text-sm">Stocks</span>
                        </Link> */}

                        </div>
                    </> :
                        <>
                            <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="/todaycalls">
                                <span className="text-14px">Home</span>
                            </Link>
                            <Link className="h-40px px-16px rounded-full text-wtf-gunmetal / hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="/">
                                <span className="text-14px">News</span>
                            </Link>
                            <Link className="h-40px px-16px rounded-full text-wtf-gunmetal / hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center" to="/">
                                <span className="text-14px">Business News</span>
                            </Link>
                            <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center btn-orange text-white" to="/previouscalls">
                                <span className="text-14px">Previous Calls</span>
                            </Link>
                            <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center text-white" to="/TodayCalls">
                                <span className="text-14px"> Today Calls</span>
                            </Link>
                            <Link className="h-40px px-16px rounded-full text-wtf-gunmetal /  hover:bg-wtf-cultured / transition duration-150 ease-in-out / flex items-center text-white mr-3" to="#" onClick={this.signOutClick}>
                                <span className="text-14px">Logout</span>
                            </Link>                        </>
                    }
                </div>

                {/* <marquee behavior="scroll" scrollamount="5" direction="left">*/}
                
                {/*    <div class="tradingview-widget-container">*/}
                {/*        <div class="tradingview-widget-container__widget" id="tradenews"></div>*/}
                       
                {/*</div>*/}
                <div className="tradingview-widget-container" ref={this.myRef}>
                    <div className="tradingview-widget-container__widget"></div>
                </div>
           
                    {/*<Link className="wh_bse" to="" target="_blank">*/}
                    {/*    <b>HINDUNILVR</b> 2,656.95<b className="red_bse"> -28.50 (-1.06%) <i className="bi-caret-down-fill"></i></b>*/}
                    {/*</Link>*/}
                    {/*<Link className="wh_bse" to="#" target="_blank">*/}
                    {/*    <b>HDFC</b> 2,708.2<b className="red_bse"> -20.25 (-0.74%) <i className="bi-caret-down-fill"></i></b>*/}
                    {/*</Link>*/}
                    {/*<Link className="wh_bse" to="#" target="_blank">*/}
                    {/*    <b>INFY</b> 1,553.9<b className="red_bse"> -26.35 (-1.67%) <i className="bi-caret-down-fill"></i></b>*/}
                    {/*</Link>*/}
               {/* </marquee>*/}


                {/* Loginpopup */}


                <div id="fade" className="overlay"></div>
                <div className="popup">
                    <div className='popup-text'>
                        <div className="w-full md:w-1 flex items-center">
                            <div className="w-full max-w-70 mx-auto px-4 mob-wide">
                                <div className="flex items-center justify-center">
                                    <Link to="/">
                                        <img src={Logo} alt="Logo image" />
                                    </Link>
                                </div>
                                {/*Signin process*/}
                                <div className="signin-step">
                                    <div className="text-center my-5">
                                        <h1 className="text-24 font-medium text-dark">Sign into ProfitSheets</h1>
                                    </div>

                                    <form onSubmit={this.handleSubmit}>
                                        <div className="botp">
                                            {/* <div className="mb-2">
                                            <input id="name" type="text" name="name" className="form-input" placeholder="Enter Name" required="" autofocus="" />
                                        </div> */}

                                            <div className="mb-2">
                                                <input type="text" name="Email" id="Email" className="form-input" placeholder="Enter Registered Phone Number" onChange={this.handleInputChange} required="" onKeyPress="return event.charCode >= 48 && event.charCode <= 57" />
                                            </div>
                                            <div className="mb-2 position-relative">
                                                <input type="password" name="Password" id="Password" onChange={this.handleInputChange} className="form-input" placeholder="Enter Password" required="" />
                                                <Link to="#" id="showpass" className="position-absolute z-20" style={{ right: "26px", top: "12px" }} onClick={this.showpass}><i className="fa fa-eye fa-lg text-dark"></i></Link>
                                            </div>
                                            {/* <p className="mx-1 text-right">
                                            <a to="#" className="text-grey hover:text-wtf-majorelle frg">Forgot your password?</a>
                                        </p> */}
                                            <div className="mt-1">
                                                <button type="submit" className="btn btn-primary btn-large w-full send" id='send'>
                                                    <span className="mx-auto">Login</span>
                                                </button>
                                                <div className="message pt-2">{this.state.Message ? <h6 className="text-danger pt-2"><strong>{this.state.Message}</strong></h6> : null}</div>

                                            </div>
                                        </div>
                                        {/*<div className="afotp">*/}
                                        {/*    <div className="mb-2">*/}
                                        {/*        <p className='text-center'>OTP Sent to - <strong>3874328974</strong></p>*/}
                                        {/*    </div>*/}

                                        {/*    <div className="mb-2">*/}
                                        {/*        <input id="partitioned" type="text" name="otp" required="" maxlength="4" />*/}
                                        {/*    </div>*/}

                                        {/*    <div className="mt-1">*/}
                                        {/*        <button type="button" className="btn btn-primary btn-large w-full">*/}
                                        {/*            <span className="mx-auto">Submit</span>*/}
                                        {/*        </button>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </form>
                                    <div className="flex items-center justify-center">
                                        <p className="mx-1 text-dark">
                                            Don't have an account <Link to="/Account/Register" className="text-grey hover:text-wtf-majorelle sign register-div">Sign up Now</Link>
                                        </p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <Link className="popup-close" to="#"><i className="fa fa-times text-dark fa-lg"></i></Link>
                </div>
            </div>

        );
    }
}
