import React, { Component } from 'react';
import { ReactSession } from 'react-client-session';
import { Link } from 'react-router-dom';
import AdSense from 'react-adsense';
import $ from 'jquery';


export class NewsDetails extends Component {
    static displayName = NewsDetails.name;
    constructor(props) {
        super(props);
        this.state = { NewsData: [], NewsIData: [], loading: true };
    }
    componentDidMount() {
        this.populateNewsDetails();
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    refreshData = (data) => {
        this.populateNewsDetails();
    };
    renderNewsTable(NewsData, refreshData) {
        return (

            NewsData.length == 0 ? <div className="text-center h6">No News Data</div> : NewsData.slice(0, 3).map(w =>
                <div className="col-xl-4 mb-3">
                    <div className="box-wrap">
                        <img src={w.imagePath} alt="image" className="img-responsive" />
                        <small className='text-muted'>{w.created_On}</small>
                        <p className="font-title"><strong>{w.news_Title} </strong></p>
                        <small className="desc">{w.news_Description}</small>
                    </div>
                </div>

            )
            //</div>

        );
    }

    renderNewsITable(News, refreshData) {
        return (
            News.length == 0 ? <div className="text-center h6">No News Data</div> : News.map(w =>
                <div key={w.news_Id} className="col-xl-4 mb-3">
                    <Link to={`/News/${w.news_Id}`} className="card-gaCYEutU">
                        <article className="card-exterior-RYyl75pN article-gaCYEutU">
                            <div className="container-gaCYEutU">
                                <div className="header-gaCYEutU">
                                    <span className="breadcrumbs-gaCYEutU"><time datetime="Thu, 29 Dec 2022 12:05:07 GMT"><i className="fa fa-clock text-dark"></i>  {w.created_On}</time></span>
                                    {/* <span className="name">Reuters</span></span>*/}
                                </div>
                                <span className="title-gaCYEutU">{w.news_Title}</span>
                            </div>
                        </article>
                    </Link>
                </div>
            )
        );
    }
    renderNewsIITable(NewsIData, refreshData) {
        return (
            NewsIData.length == 0 ? <div className="text-center h6">No News Data</div> : NewsIData.map(w =>
                <div className="col-xl-6 mb-3 mx-auto">
                    <Link className="close" to="/">&times;</Link>
                    <h2 className="text-dark h1 pb-0">{w.news_Title}</h2>
                    <div className="w-100">
                        <Link onClick={this.fbshareCurrentPage} alt="Share on Facebook" className="fb-icon mt-5" title="facebook share"><i className="fab fa-facebook-f"></i></Link>

                        <Link onClick={this.whatsappshareCurrentPage} alt="Share on WhatsApp" className="wt-icon mt-5 ml-3" title="WhatsApp share"><i className="fab fa-whatsapp"></i></Link>

                        <Link onClick={this.twittershareCurrentPage} alt="Share on Twitter" className="tw-icon mt-5 ml-3" title="twitter share"><i className="fab fa-twitter"></i></Link>

                        <Link onClick={this.linkshareCurrentPage} alt="Share on Linkedin" className="link-icon mt-5 ml-3" title="Linkedin share"><i className="fab fa-linkedin"></i></Link>
                        </div>
                    <span className="date small"><i className="fa fa-clock text-dark"></i> {w.created_On}</span>
                    {ReactSession.get("token") == undefined ? <>
                        <div className="font-weight-normal pt-4 pb-3" dangerouslySetInnerHTML={{ __html: w.news_Description.substring(0, 150) }}></div>
                        <div className="fullstory-box bg-blue p-4">
                            <div className="row">
                                <div className="col-xl-9 text-white">
                                    <h4 className="h2">Keep reading</h4>
                                    <p >Log in or sign up for a free Profitsheets account, and enjoy no holds barred access to global finance news, in real-time.</p>
                                    <Link to='/' className="btn-success btn-lg mt-4 d-inline-block blogin text">Get full story</Link>
                                </div>
                                <div className="col-xl-3 text-center mx-auto">
                                    <i className="fa fa-lock-alt text-white fa-5x pt-5"></i>
                                </div>
                            </div>
                        </div>
                    </> :
                        <>
                            <div className="font-weight-normal pt-4 pb-3" dangerouslySetInnerHTML={{ __html: w.news_Description }}></div>
                        </>

                    }
                </div>
            )
            //</div>
        );
    }

    fbshareCurrentPage = (e) => {
        {
            window.open("http://www.facebook.com/sharer.php?u=" + encodeURIComponent(window.location.href) + "&t=" + document.title, '',
                'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
            return false;
        }
    }

    whatsappshareCurrentPage = (e) => {
        window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent(window.location.href) + "&t=" + document.title, '',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
        return false;
    }

    twittershareCurrentPage = (e) => {
        window.open("https://twitter.com/intent/tweet?url=" + encodeURIComponent(window.location.href) + "&t=" + document.title, '',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
        return false;
    }

    linkshareCurrentPage = (e) => {
        window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(window.location.href) + "&t=" + document.title, '',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
        return false;
    }

    render() {
        
        setTimeout(function () {
            $('.text').on("click", function () {
                $('.popup').css('opacity', 1);
                $('.overlay').css('opacity', 0.8);
                $('.popup').css('display', 'block');
                $('.overlay').css('display', 'block');
            });
            // close popup
            $('.popup-close').on("click", function () {
                $('.popup').css('opacity', 0);
                $('.overlay').css('opacity', 0);
                setTimeout(function () {
                    $('.popup').css('display', 'none');
                    $('.overlay').css('display', 'none');
                }, 10);

            });
            //    $("#send").on("click",function(){
            //        $(".botp").hide();
            //        $(".afotp").show();
            //});


        }, 1);
        setTimeout(function () {
            $('.header, .footer').show();
        }, 1);
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderNewsTable(this.state.NewsData, this.refreshData);
        let contents1 = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderNewsITable(this.state.NewsIData, this.refreshData);
        let contents2 = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderNewsIITable(this.state.NewsIData, this.refreshData);

        setTimeout(function () {

            $('.popupp').css({ 'opacity': '1', 'visibility': 'visible' });
            $('.overlayy').css({ 'opacity': '1', 'visibility': 'visible' });

            $('.close').on("click", function () {
                $('.popupp').css({ 'opacity': '0', 'visibility': 'hidden' });
                $('.overlayy').css({ 'opacity': '0', 'visibility': 'hidden' });
            });
           
        }, 1);


        return (

            <div>
                <div id="popupp" className="overlayy">
                    <div className="popupp">

                        <div className="content row">
                            <div className="col-xl-3 mb-3 mx-auto">

                                <AdSense.Google
                                    client='ca-pub-4276578180024665'
                                    slot='9900973965'
                                    style={{ display: 'block', height: '500' }}
                                    format='auto'
                                    responsive='true'
                                    layoutKey='-gw-1+2a-9x+5c'
                                />
                                
                            </div>
                            
                            {contents2}
                            <div className="col-xl-3 mb-3 mx-auto">
                                <ins className="adsbygoogle"
                                    style={{ display: "block", height: '500' }}
                                    data-ad-client="ca-pub-4276578180024665"
                                    data-ad-slot="9900973965"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );

    }
    async populateNewsDetails() {
        const news_Id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
        ReactSession.setStoreType("localStorage");

        const response1 = await fetch('/api/Values/GetINewsById?Id=' + news_Id);
        if (response1.status == '401') {
            this.props.history.push("/");
        }
        else {
            const data1 = await response1.json();
            this.setState({ NewsIData: data1.data, loading: false });
        }
    }
}
