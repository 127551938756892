import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import './Login.css';
import Msgicon from '../wp-content/themes/ProfitSheets/images/message.svg';
import UserIcon from '../wp-content/themes/ProfitSheets/images/user-icon.jpg';
import Logo from '../wp-content/uploads/2021/logo/logo.png';

export class Register extends Component {
    static displayName = Register.name;
    constructor(props) {
        super(props);

        this.state = { Name: "", Phone: "", Email: "", Password: "", ConfirmPassword: "", Message: "" };
        this.handleInputChange = this.handleInputChange.bind(this);

    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }
    validate() {
        let messageError = "";
        if (!this.state.Name) {
            messageError = "Name is required";
        }
        if (!this.state.Phone) {
            messageError = "Phone is required";
        }
        if (!this.state.Email) {
            messageError = "Email is required";
        }
        if (!this.state.Password) {
            messageError = "Password is required";
        }
        if (!this.state.ConfirmPassword) {
            messageError = "ConfirmPassword is required";
        }
        if (!(!this.state.Password && !this.state.ConfirmPassword)) {
            if (this.state.Password != this.state.ConfirmPassword)
                messageError = "Password doesn't match";
        }
        if (messageError) {
            this.setState({ Message: messageError });
            return false;
        }
        else {
            this.setState({ Message: "" });
        }
        return true;
    }
    handleSubmit = (e) => {
        e.preventDefault();
        if (this.validate()) {
            try {
                fetch('/api/Values/Register', {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: "POST",
                    body: JSON.stringify({
                        Name: this.state.Name,
                        Phone: this.state.Phone,
                        Email: this.state.Email,
                        Password: this.state.Password,
                    })
                }).then((response) => {
                    return response.json();
                })
                    .then((jsonData) => {
                        if (jsonData.isSuccess) {
                            this.props.history.push("/?islogin=1");
                            //ReactSession.setStoreType("localStorage");
                            //ReactSession.set("login_datetime", new Date());
                            //ReactSession.set("user_id", jsonData.data.id);
                            //ReactSession.set("username", jsonData.data.username);
                            //ReactSession.set("session_user", jsonData.data);
                            //this.props.history.push("/login");
                        }
                        else {
                            this.setState({ Message: jsonData.message });
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        }
    }

    render() {
        setTimeout(function () {
            $('.header, .footer').hide();


            $(".afotp").hide();
            $("#send").on("click", function () {
                $(".botp").hide();
                $(".afotp").show();
            });
        }, 1);
        return (
            <div className="Register">
                <div className="flex min-h-screen">
                    <div className="w-full md:w-1/2 flex items-center">
                        <div className="w-full max-w-70 mx-auto px-4 py-15">
                            <div className="flex items-center justify-center">
                                <Link to="/">
                                    <img src={Logo} alt="Logo image" />
                                </Link>
                            </div>

                            <div className="register-step">
                                <div className="text-center my-5">
                                    <h1 className="text-24 font-medium">Signup into ProfitSheets</h1>
                                </div>

                                <form onSubmit={this.handleSubmit}>
                                    <div className='botp'>
                                        <div className="mb-2">
                                            <input id="name" type="text" name="Name" className="form-input" onChange={this.handleInputChange} placeholder="Enter Name" required="" autocomplete="" autoFocus="" />
                                        </div>
                                        <div className="mb-2">
                                            <input id="email" type="email" name="Email" className="form-input" onChange={this.handleInputChange} placeholder="Enter Email ID" required="" autocomplete="" />
                                        </div>

                                        <div className="mb-2">
                                            <input id="phone" type="text" maxLength="10" name="Phone" className="form-input" onChange={this.handleInputChange} placeholder="Enter Phone Number" required="" autocomplete="" autoFocus="" />
                                        </div>

                                        <div className="mb-2">
                                            <input id="password" type="password" name="Password" onChange={this.handleInputChange} className="form-input" placeholder="Enter Password" required="" autocomplete="" autoFocus="" />
                                        </div>
                                        <div className="wrap-input100 validate-input">
                                            <input className="form-input" type="password" name="ConfirmPassword" id="ConfirmPassword" onChange={this.handleInputChange} placeholder="Re-Enter Password" />
                                        </div>

                                        <div className="mt-4">
                                            <button type="submit" className="btn btn-primary btn-large w-full" id="send">
                                                <span className="mx-auto">Register</span>
                                            </button>
                                            <div className="message">{this.state.Message ? <p className="text-danger">{this.state.Message}</p> : null}</div>

                                        </div>
                                    </div>
                                    <div className="afotp">
                                        <div className="mb-2">
                                            <p className='text-center'>OTP Sent to - <strong>3874328974</strong></p>
                                        </div>
                                        <div className="mb-2">
                                            <input id="partitioned" type="text" name="otp" required="" maxlength="4" />
                                        </div>

                                        <div className="mt-1">
                                            <button type="button" className="btn btn-primary btn-large w-full">
                                                <span className="mx-auto">Submit</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <div className="text-center">
                                    <Link to="/" className="blogin text-dark">Back to Login</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="relative hidden md:block md:w-1/2 bg-wtf-pigmentblue relative overflow-hidden">
                        <svg className="absolute top-0 left-0" width="720" height="787" viewBox="0 0 720 787" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-392.905 -502.476L-391.004 -501.739C-391.004 -501.739 326.491 -197.031 501.442 -56.8491C676.392 83.3324 662.208 352.981 508.59 506.599C354.972 660.217 85.3233 674.401 -54.8582 499.451C-195.04 324.5 -499.748 -392.994 -499.748 -392.994L-500.485 -394.895C-516.584 -436.403 -527.156 -463.658 -494.411 -496.402C-461.667 -529.146 -434.412 -518.575 -392.905 -502.476Z" fill="#7047EB"></path>
                        </svg>
                        <svg className="absolute top-0 left-0" width="392" height="487" viewBox="0 0 392 487" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-336.818 -308.216L-335.645 -307.762C-335.645 -307.762 106.836 -120.048 214.706 -33.6373C322.576 52.7737 313.735 219.111 218.923 313.922C124.112 408.734 -42.2253 417.575 -128.636 309.705C-215.047 201.835 -402.761 -240.646 -402.761 -240.646L-403.215 -241.819C-413.132 -267.417 -419.644 -284.226 -399.434 -304.435C-379.225 -324.644 -362.416 -318.133 -336.818 -308.216Z" fill="#FCC5F3"></path>
                        </svg>
                        <svg className="absolute bottom-0 left-0" width="632" height="316" viewBox="0 0 632 316" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M-351.418 900.397L-350.856 898.948C-350.856 898.948 -118.665 351.944 -11.8154 218.578C95.0341 85.2126 300.633 96.0786 417.79 213.235C534.947 330.392 545.813 535.991 412.447 642.841C279.081 749.69 -267.923 981.882 -267.923 981.882L-269.372 982.444C-301.016 994.711 -321.795 1002.77 -346.768 977.793C-371.74 952.821 -363.685 932.042 -351.418 900.397Z" fill="#2DCA72"></path>
                        </svg>
                        <svg className="absolute bottom-0 right-0" width="477" height="768" viewBox="0 0 477 768" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M334.917 239.653L336.582 240.299C336.582 240.299 965.06 507.074 1118.29 629.839C1271.52 752.603 1259.04 988.825 1124.43 1123.43C989.823 1258.04 753.6 1270.52 630.836 1117.29C508.071 964.063 241.296 335.585 241.296 335.585L240.65 333.92C226.556 297.562 217.301 273.688 245.993 244.996C274.685 216.304 298.559 225.559 334.917 239.653Z" fill="#00ACFF"></path>
                        </svg>

                        <div className="absolute inset-0 flex items-center justify-center">
                            <div className="w-39 px-5 py-4 bg-white rounded shadow-menu">
                                <div className="w-6 h-6">
                                    <img className="block w-6 h-6" src={Msgicon} alt="message icon" />
                                </div>

                                <p className="text-16 mt-2">
                                    Before ProfitSheets Extension, Thayer’s biggest problem was reaching his contacts via phone. Now, Thayer is able to quickly source his prospect’s contact details
                                </p>

                                <div className="relative flex items-center mt-3">
                                    <img className="block w-6 h-6 rounded-full" src={UserIcon} alt="user icon" />
                                    <div className="ml-2">
                                        <h4 className="text-16 font-medium">Thayer Selleck</h4>
                                        <span className="text-wtf-wildblue">Account Manager</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}
